<template>
  <div id="box">
    <el-container>
      <el-header>
        <h1>人才政策清单</h1>
        <el-input placeholder="请输入政策内容" suffix-icon="el-icon-search">
        </el-input>
      </el-header>
      <el-main>
        <div id="container" v-for="(item, index) in dataList" :key="index">
          <h4>{{ item.title }}</h4>
          <div class="demo1">
            <img src="../../assets/img/projecttype.png" alt="" />
            <span>{{ item.content }}</span
            ><span>{{ item.icon }}</span>
            <img src="../../assets/img/building.png" />
            <span>{{ item.publishcompany }}</span
            ><span>{{ item.icon }}</span>
            <img src="../../assets/img/clock.png" alt="" />
            <span>{{ item.date }}</span>
          </div>
          <div class="demo2">
            <img src="../../assets/img/file.png" alt="" />
            <span>{{ item.awardquota }}</span>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList: [
        {
          title: "龙港市新增高层次人才（ABCD类） 奖补（个人）",
          content: "项目类型:数据检验类",
          publishcompany: "发布单位：龙港市经济发展局",
          icon: "|",
          date: "申报时间：2021年11月15日-2021年11月21日",
          awardquota:
            " 奖励额度：对新全职引进或自主申报入选的A类、B类、 C类、D类人才，5年内给予总额750万元、450万元、300万元、40—100万元.....",
        },
        {
          title: "龙港市首批“龙城鲲鹏”计划入选者奖补（个人）",
          content: "项目类型:数据检验类",
          publishcompany: "发布单位：龙港市经济发展局",
          icon: "|",
          date: "申报时间：2021年11月15日-2021年11月21日",
          awardquota:
            "奖励额度：对龙港市首批“龙城鲲鹏”计划入选者按科技创新人才、科技创业人才、名教师、名医生、企业经营管理人才、人文社科人才.....",
        },
        {
          title: "龙港市首批“龙城鲲鹏”计划入选者奖补（个人）",
          content: "项目类型:数据检验类",
          publishcompany: "发布单位：龙港市经济发展局",
          icon: "|",
          date: "申报时间：2021年11月15日-2021年11月21日",
          awardquota:
            "奖励额度：对龙港市首批“龙城鲲鹏”计划入选者按科技创新人才、科技创业人才、名教师、名医生、企业经营管理人才、人文社科人才.....",
        },
        {
          title:
            "龙港市事业单位引进中高层次人才和“双一流”高校毕业生（2019年通过苍南县招考公告入选者）生活奖补（个人）",
          content: "项目类型:数据检验类",
          publishcompany: "发布单位：龙港市经济发展局",
          icon: "|",
          date: "申报时间：2021年11月15日-2021年11月21日",
          awardquota:
            "奖励额度：按全日制博士、全日制硕士、全国“双一流” 高校（2017年9月21日国家正式发布“双一流”高校名单以前的毕业生院校范围为原.....",
        },
        {
          title:
            "龙港市事业单位引进中高层次人才和“双一流”高校毕业生（2019年通过苍南县招考公告入选者）生活奖补（个人）",
          content: "项目类型:数据检验类",
          publishcompany: "发布单位：龙港市经济发展局",
          icon: "|",
          date: "申报时间：2021年11月15日-2021年11月21日",
          awardquota:
            "奖励额度：在国内外高校、科研机构担任相当于副教授及以上职务，或在知名企业、金融机构担任高级职务的专业技术人才和经营管理人才.....",
        },
        {
          title:
            "龙港市事业单位引进中高层次人才和“双一流”高校毕业生（2019年通过苍南县招考公告入选者）生活奖补（个人）",
          content: "项目类型:数据检验类",
          publishcompany: "发布单位：龙港市经济发展局",
          icon: "|",
          date: "申报时间：2021年11月15日-2021年11月21日",
          awardquota:
            "奖励额度：2020年9月至2021年9月期间，毕业5年以内的高校毕业生初次来温创办企业并担任法定代表人或从事个体经营.....",
        },
      ],
    };
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.el-header {
  background-color: #b3c0d1;
  color: #ccc;
  text-align: center;
  line-height: 60px;
}
body > .el-container {
  margin-bottom: 40px;
}
.el-main {
  margin-top: 20px;
  /* background-color: #e9eef3; */
  color: #333;
  text-align: center;
  position: relative;
}
.el-header {
  background-image: url("../../assets/img/zhengce.png");
  background-size: 100% 100%;
  /* z-index: 1; */
}
.el-input {
  position: relative;
  top: -60px;
  right: -460px;
  width: 200px;
}
.el-header >>> .el-input__inner {
  background: transparent;
}
#container {
  position: relative;
  height: 130px;
  /* border: 1px solid black; */
  padding-left: 50px;
  margin-bottom: 10px;
  border-bottom: 1px dashed #ccc;
  padding-top: 10px;
margin-left: 50px;
  opacity: 0.9;
}
.demo1 {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  
}
#container span {
  font-size: 10px;
}
#container h4 {
  color: #1492ff;
  float: left;
}
.demo2 {
  padding-bottom: 10px;
  position: absolute;
  top: 80px;
  left: 48px;
}
.demo2 span {
  margin-left: 30px;
}
</style>